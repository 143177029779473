@bcColor: #151515;
.container {
  width: 420px;
  margin: 100px auto 0px;
  > h1 {
    font-size: 2rem;
    text-align: center;
    color: var(--app-text-color);
    margin-bottom: 20px;
  }
  > .form {
    .staySignedIn {
      color: var(--app-primary-color);
      margin-right: 85px;
      font-weight: bold;
    }
    .homeContent {
      .inputItem {
        height: 44px;
        background: #151515;
        border-radius: 10px;
        border: none;

        :global {
          .next-antd-input {
            padding-left: 5px;
            color: #ffffff;
            background: #151515 !important;
            font-size: 0.8rem;
            font-weight: bolder;
            &::placeholder {
              color: #ffffff;
              font-weight: bolder;
              font-size: 0.8rem;
            }
          }
          input:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 30px #151515 inset !important;
            box-shadow: 0 0 0 30px #151515 inset !important;
            -webkit-text-fill-color: #ffffff !important;
            text-fill-color: #ffffff !important;
          }
        }
      }
    }
    .loginFormButton {
      width: 100%;
      height: 44px;
      font-weight: bold;
      color: #000000;
      &:hover {
        color: #000000;
      }
    }
    .createFormButton {
      width: 100%;
      height: 44px;
      background: @bcColor;
      color: var(--app-primary-color);
      border: 1px solid var(--app-primary-color);
      font-weight: bold;
      margin-top: 1rem;
    }

    .googleLoginText {
      margin-top: 0;
      text-align: center;
      margin-bottom: 20px;
      span {
        color: var(--app-text-color);
        font-size: 1rem;
        font-weight: bold;
      }
      span:last-child {
        font-weight: normal;
      }
      &::after {
        background-color: #787878;
      }
      &::before {
        background-color: #787878;
      }
    }
  }
  .googleFormButton {
    width: 100%;
    height: 44px;
    background: @bcColor;
    color: var(--app-text-color);
    border: 0.5px solid @bcColor;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: var(--app-text-color);
      font-size: 1rem;
      padding-left: 5px;
    }

    span:nth-child(1) {
      font-weight: bold;
    }
    span:nth-child(2) {
      font-weight: bold;
    }
  }
  .forgotItem {
    // float: right;
    // display: block;
    width: 100%;
    text-align: center;
    font-size: 0.875rem;
    color: var(--app-primary-color);
  }

  .agreeDesc {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    margin-bottom: 20px;

    :first-child {
      margin-right: 5px;
    }

    span {
      color: var(--app-text-color);
    }

    .link {
      display: inline-block;
      color: var(--app-primary-color);
    }
  }
}
@media screen and (max-width: 440px) {
  .container {
    width: 380px;
    .staySignedIn {
      margin-right: 70px !important;
    }
  }
}

